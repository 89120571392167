import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { navigate } from "gatsby-link";
import { ListType } from "../../../../types/list";
import Download from "../DoButtons/Download";
import { SessionFeed } from "../SessionFeed";
import PhotoGallery from "./PhotoGallery";
import { useUserImages } from "../../../../util/hooks/useUserImages";
import Share from "../DoButtons/Share";
import { User } from "../../../../types/user";

const Profile = ({
  user,
  logs,
  loading,
}: {
  user: User;
  logs: any;
  loading: boolean;
}) => {
  const { images, loading: imagesLoading } = useUserImages(
    user.id,
    user.aws_access_token
  );

  // Prepared logs for with distance, items, and hours
  const preppedLogs =
    logs &&
    logs.map((log) => {
      const distance = log.distance || 0;
      const items = log.items
        ? log.items.reduce((prev, curr) => prev + curr.quantity, 0)
        : 0;
      const hours = log.duration ? log.duration / 3600 : 0;
      return { distance, items, hours };
    });

  const totalDistance =
    (preppedLogs &&
      preppedLogs
        .filter(Boolean)
        .reduce((prev, curr) => prev + curr.distance, 0)) ||
    0;
  const totalItems =
    (preppedLogs && preppedLogs.reduce((prev, curr) => prev + curr.items, 0)) ||
    0;
  const totalHours =
    (
      preppedLogs && preppedLogs.reduce((prev, curr) => prev + curr.hours, 0)
    ).toFixed(2) || 0;

  return (
    <SessionFeed.Profile.Container>
      <SessionFeed.Profile.Wrapper>
        {/* <ProfilePicture /> */}
        <SessionFeed.Profile.Content>
          <SessionFeed.Profile.Title>
            {user &&
            user.firstname &&
            user.lastname &&
            user.firstname !== "unknown" &&
            user.lastname !== "unknown"
              ? `${user.firstname} ${user.lastname}`
              : `${user.mdtname}`}
          </SessionFeed.Profile.Title>
          <div>
            {/* <SessionFeed.Profile.Subtitle>
                  The Shire, Middle Earth
                </SessionFeed.Profile.Subtitle>
                <SessionFeed.Profile.Subtitle>
                  Joined Whenever
                </SessionFeed.Profile.Subtitle> */}
          </div>
        </SessionFeed.Profile.Content>
      </SessionFeed.Profile.Wrapper>
      <SessionFeed.Stats.Wrapper>
        <SessionFeed.Stats.ActivityStat.Wrapper>
          <SessionFeed.Stats.ActivityStat.Text>
            {totalItems}
          </SessionFeed.Stats.ActivityStat.Text>
          <SessionFeed.Stats.ActivityStat.Label>
            ITEMS
          </SessionFeed.Stats.ActivityStat.Label>
        </SessionFeed.Stats.ActivityStat.Wrapper>
        <SessionFeed.VerticalDivider />
        <SessionFeed.Stats.ActivityStat.Wrapper>
          <SessionFeed.Stats.ActivityStat.Text>
            {totalHours}
          </SessionFeed.Stats.ActivityStat.Text>
          <SessionFeed.Stats.ActivityStat.Label>
            HOURS
          </SessionFeed.Stats.ActivityStat.Label>
        </SessionFeed.Stats.ActivityStat.Wrapper>
        <SessionFeed.VerticalDivider />
        <SessionFeed.Stats.ActivityStat.Wrapper>
          <SessionFeed.Stats.ActivityStat.Text>
            {Math.round(totalDistance)}
          </SessionFeed.Stats.ActivityStat.Text>
          <SessionFeed.Stats.ActivityStat.Label>
            KM
          </SessionFeed.Stats.ActivityStat.Label>
        </SessionFeed.Stats.ActivityStat.Wrapper>
      </SessionFeed.Stats.Wrapper>
      <SessionFeed.Stats.Wrapper>
        <SessionFeed.ContentSection.Wrapper>
          <SessionFeed.ContentSection.SmallHeading>
            Latest Log
          </SessionFeed.ContentSection.SmallHeading>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SessionFeed.ContentSection.SmallText
              style={{ fontWeight: "bold" }}
            >
              {!loading && logs && logs.length > 0 && logs[0].organization.name}
            </SessionFeed.ContentSection.SmallText>
            <SessionFeed.ContentSection.SmallText>
              {!loading &&
                logs &&
                logs.length > 0 &&
                dayjs(logs[0].timestamp).format("MMMM DD, YYYY")}
            </SessionFeed.ContentSection.SmallText>
          </div>
        </SessionFeed.ContentSection.Wrapper>
      </SessionFeed.Stats.Wrapper>
      <SessionFeed.Stats.Wrapper>
        <SessionFeed.ContentSection.Wrapper>
          <SessionFeed.ContentSection.SmallHeading>
            My Organizations
          </SessionFeed.ContentSection.SmallHeading>
          {user &&
            user.adminOrgs &&
            user.adminOrgs.map((org: ListType, i: number) => {
              return (
                org && (
                  <SessionFeed.ContentSection.OrgLink
                    key={`org_${i}`}
                    style={{ alignItems: "center" }}
                    onClick={() => {
                      navigate(`/dashboard/list/${org.id}`);
                    }}
                  >
                    <SessionFeed.OrgImage
                      src={`https://marinedebris.engr.uga.edu/mdtapp/images/${org.icon_image_filename}`}
                    />

                    <SessionFeed.ContentSection.SmallText
                      style={{ fontWeight: "bold" }}
                    >
                      {org.name}
                    </SessionFeed.ContentSection.SmallText>
                  </SessionFeed.ContentSection.OrgLink>
                )
              );
            })}
        </SessionFeed.ContentSection.Wrapper>
      </SessionFeed.Stats.Wrapper>
      <PhotoGallery images={images} loading={imagesLoading} />
      <SessionFeed.Stats.Wrapper>
        <SessionFeed.ContentSection.Wrapper>
          <SessionFeed.ContentSection.SmallHeading>
            Tools
          </SessionFeed.ContentSection.SmallHeading>
          <div style={{ display: "flex" }}>
            <Share title={"Manually upload data"} />
            <Download
              data={
                logs &&
                logs
                  .filter(Boolean)
                  .reduce((prev, curr) => [...prev, ...(curr.items || [])], [])
              }
              filename={`${user.mdtname}-all-data.csv`}
              title={"Download all data"}
            />
            {/* <Settings /> */}
          </div>
        </SessionFeed.ContentSection.Wrapper>
      </SessionFeed.Stats.Wrapper>
    </SessionFeed.Profile.Container>
  );
};

export default Profile;
