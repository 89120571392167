import { devAPI } from "../util/urls";

export const verifyProjectById = async (id: string) => {
  try {
    const response = await fetch(`${devAPI}/projects/${id}/verify`, {
      method: "GET",
    });
    const res = await response.json();

    if (response.status !== 200) throw res.message;

    return res.message;
  } catch (e) {
    console.error(e);
    return null;
  }
};
