import React, { useEffect, useState } from "react";
import { SessionFeed } from "../SessionFeed";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { colors } from "../../../../styles/colors";

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #000000b2;
  z-index: 50000;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease;
`;

const GalleryImageWrapper = styled.div`
  cursor: pointer;
`;

const FullSizeImg = styled.img`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0px, 0px, 0px) scale3d(0.653333, 0.653333, 1);
`;

const OverlayNav = styled.div`
  background-color: #000000d5;
  height: 60px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center; ;
`;

const OverlayBottom = styled.div`
  background-color: #000000d5;
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Close = styled.button`
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZD0iTTEgM2wxLjI1LTEuMjUgNy41IDcuNSA3LjUtNy41TDE4LjUgMyAxMSAxMC41bDcuNSA3LjUtMS4yNSAxLjI1LTcuNS03LjUtNy41IDcuNUwxIDE4bDcuNS03LjVMMSAzeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==")
    no-repeat 50%;
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

const MenuOptions = styled.div`
  display: flex;
`;

// const MenuText = styled.p`
//   color: ${colors.orange};
//   margin: 0px 10px;
//   cursor: pointer;
// `;

const MenuLink = styled.a`
  color: ${colors.orange};
  margin: 0px 10px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
`;

const DownloadButton = styled.div`
  cursor: pointer;
  padding: 20px;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 0.5fr);
  grid-row-gap: 2px;
  grid-column-gap: 2px;
`;

const DownloadMenu = ({ src }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  return (
    <>
      {showMenu && (
        <MenuOptions>
          {/* <MenuText>Delete Image</MenuText> */}
          <MenuLink href={src} download={src.split("/")[src.split("/").length]}>
            Download Image
          </MenuLink>
        </MenuOptions>
      )}
      <DownloadButton
        onClick={(e: any) => {
          // toggle menu
          e.preventDefault();
          showMenu ? setShowMenu(false) : setShowMenu(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="white"
          height="16"
          width="16"
        >
          <path
            d="M2 6C.897 6 0 6.897 0 8s.897 2 2 2 2-.897 2-2-.897-2-2-2m12 0c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2M8 6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2"
            fill-rule="evenodd"
          ></path>
        </svg>
      </DownloadButton>
    </>
  );
};

const Overlay = (props) => {
  return createPortal(props.children, document.getElementById("___gatsby"));
};

const PhotoGallery = ({ images, loading }: { images; loading }) => {
  const [selectedImg, setSelectedImg] = useState<any>(undefined);
  const [decodedImages, setDecodedImages] = useState([]);

  useEffect(() => {
    if (images && images.length > 0) {
      const promises = images.slice(0, 3).map((image) => {
        const src = `https://debristracker-images.s3.amazonaws.com/uploads/${
          image.image_uri.split("/")[image.image_uri.split("/").length - 1]
        }`;
        return src;
      });

      Promise.all(promises).then((decodedSrcs) => {
        setDecodedImages(decodedSrcs);
      });
    }
  }, [images]);

  return (
    <>
      {selectedImg && (
        <Overlay>
          <ImageOverlay
            style={{ opacity: selectedImg ? 1 : 0 }}
            onClick={() => {
              setSelectedImg(undefined);
            }}
          >
            <OverlayNav
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <DownloadMenu src={selectedImg} />
              <Close
                onClick={() => {
                  setSelectedImg(undefined);
                }}
              />
            </OverlayNav>
            <FullSizeImg src={selectedImg} />
            <OverlayBottom />
          </ImageOverlay>
        </Overlay>
      )}
      <SessionFeed.Stats.Wrapper>
        <SessionFeed.ContentSection.Wrapper>
          <SessionFeed.ContentSection.SmallHeading>
            Recent Photos
          </SessionFeed.ContentSection.SmallHeading>
          <GalleryGrid>
            {!loading &&
              decodedImages.length > 0 &&
              decodedImages.map((decodedSrc, i) => (
                <GalleryImageWrapper key={`image_${i}`}>
                  <GalleryImage
                    onClick={() => {
                      setSelectedImg(decodedSrc);
                    }}
                    src={decodedSrc}
                  />
                </GalleryImageWrapper>
              ))}
          </GalleryGrid>
        </SessionFeed.ContentSection.Wrapper>
      </SessionFeed.Stats.Wrapper>
    </>
  );
};

export default PhotoGallery;
