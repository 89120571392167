import React from "react";
import { SessionFeed } from "../SessionFeed";
import styled from "styled-components";
import share from "../../../../images/mdt_share_icon.png";
import { navigate } from "gatsby-link";

const ShareButton = styled(SessionFeed.DoButton)`
  background-color: #fabb5c;
  background-image: url(${share});
  background-position: 55% 50%;
  background-size: 40%;
  background-repeat: no-repeat;
`;

const Share = ({ title }: { title }) => {
  return (
    <ShareButton
      title={title}
      onClick={() => {
        navigate("/manual-upload");
      }}
    />
  );
};

export default Share;
