import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  createRef,
} from "react";
import _ from "lodash";
import styled from "styled-components";
// import { logPage } from "../util/gaFunctions";
//import ListStatsContainer from "../containers/ListStatsContainer";
import { DebrisTrackerContext } from "../../context/DebrisTrackerContext";
import { navigate } from "gatsby";
// import { surveyJSONtoCSV } from "../../util/data/surveyJSONtoCSV";
import ListUsersContainer from "./ListDashboard/ListUsersContainer";
import { Callout, StyledTitle } from "../../styles/StyledComponents";
import ProjectsTable from "./ListDashboard/ProjectsTable";
import numberWithCommas from "../../util/numberWithCommas";
import CollectionEventBarChartContainer from "./ListDashboard/CollectionEventBarChartContainer";
import { surveyJSONtoCSV } from "../../util/data/surveyJSONtoCSV";
import LoadingOverlay from "react-loading-overlay-ts";

import { colors } from "../../styles/colors";
import { CSVLink } from "react-csv";
import { useListDashboardData } from "../../util/hooks/useListDashboardData";
import { SurveyResult } from "../../types/survey";
import ListAdminsTable from "./ListDashboard/ListAdminsTable";

const countItems = (items: any[], key: string) => {
  return Object.entries(_.groupBy(items, key))
    .map((k) => {
      return { name: k[0], count: k[1].length, item: k[1][0] };
    })
    .sort((a, b) => b.count - a.count);
};

const DashboardContainer = styled.div``;

const LocationsMapContainer = styled.div`
  height: 50vh;
`;

const ListItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
`;

const TotalsContainer = styled.div`
  flex: 1;
  margin-right: 20px;
  margin-left: 20px;
  display: inline-flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const TotalsIndividualWrapper = styled.div`
  font-size: 22px;
  color: ${colors.lightBlue};
  background-color: ${colors.white};
  padding: 20px;
  margin: 15px;
  flex-grow: 1;
  border: 1px solid ${colors.black}20;
`;

const DownloadButtonWrapper = styled(TotalsIndividualWrapper)<{
  enabled: boolean;
}>`
  background-color: ${colors.orange};
  color: ${colors.white};
  border: 0px;
  cursor: pointer;
  text-align: center;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
`;

const DataContainer = styled.div`
  margin: 20px;
  display: grid;
  grid-template-columns: 3.5fr 1fr;
`;

const LoadingContainer = styled("div")<{ visible: boolean }>`
  display: ${({ visible }) =>
    visible ? "block" : "none"}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  padding-top: 170px;
  overflow: none; /* Enable scroll if needed */
  background-color: ${colors.orange};
`;

const LoadingText = styled.p`
  color: white;
  text-align: center;
  font-size: 20px;
  margin-top: 100px; /* slightly scuff - this will probably break as screen size changes */
`;

const ListDashboard = ({ listId }: { listId: string }) => {
  const { user } = useContext(DebrisTrackerContext);

  const {
    org,
    projects,
    itemsData,
    userData,
    totalItems,
    surveyResults,
    admins,
    logCount,
    loading,
  } = useListDashboardData(listId);

  const DownloadSurvey = ({
    surveyResults,
  }: {
    surveyResults: SurveyResult[];
  }) => {
    // To properly download survey data,
    // we need to group answers by each unique set of questions.
    // then, for each question set, generate a csv link with the corresponding data.
    const resultsByQuestions = _.groupBy(surveyResults, (it) => {
      return JSON.stringify(it.survey_json.questions);
    });
    const surveyCount = Object.keys(resultsByQuestions);

    const linkRefs = useRef<any>(surveyCount.map(() => createRef()));
    const [surveyData, setSurveyData] = useState<string[]>([]);

    useEffect(() => {
      if (surveyResults && surveyResults.length > 0 && surveyCount.length > 0) {
        let newSurveyData = [...surveyData];
        for (let key of surveyCount) {
          const rawData = resultsByQuestions[key];
          const parsedData = surveyJSONtoCSV(rawData);
          newSurveyData.push(parsedData);
        }
        setSurveyData(newSurveyData);
      }
    }, [surveyResults]);

    return (
      <div>
        <DownloadButtonWrapper
          enabled={surveyData.length > 0}
          onClick={() => {
            // click all the links with data
            if (linkRefs.current && surveyData.length > 0) {
              for (let linkRef of linkRefs.current) {
                linkRef.current && linkRef.current.link.click();
              }
            }
          }}
        >
          Download Survey Data
        </DownloadButtonWrapper>
        {surveyCount.map((_survey, i) => {
          return (
            <CSVLink
              key={`survey_${i}`}
              target="_blank"
              ref={linkRefs.current[i]}
              filename={`survey-data-${i + 1}.csv`}
              data={surveyData[i] ? surveyData[i] : ""}
            />
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (!user.id) {
      navigate("/");
      return;
    }
  }, [user]);

  return (
    <DashboardContainer>
      <LoadingContainer visible={loading}>
        <LoadingOverlay
          active={loading}
          spinner={true}
          text="Loading list data."
        />
        <LoadingText>This can take a while.</LoadingText>
      </LoadingContainer>
      <DataContainer>
        <CollectionEventBarChartContainer data={itemsData} />
        <TotalsContainer>
          <TotalsIndividualWrapper>
            {userData && userData.length} Users
          </TotalsIndividualWrapper>
          <TotalsIndividualWrapper>
            {totalItems && numberWithCommas(totalItems)} Total Items
          </TotalsIndividualWrapper>
          <TotalsIndividualWrapper>
            {itemsData && numberWithCommas(itemsData.length)} Collection Events
          </TotalsIndividualWrapper>
          <TotalsIndividualWrapper>
            {itemsData && numberWithCommas(logCount)} Logs
          </TotalsIndividualWrapper>
          <DownloadSurvey surveyResults={surveyResults} />
        </TotalsContainer>
      </DataContainer>
      <ListItemsWrapper>
        <div>
          <ListUsersContainer userData={userData} />
          <ListAdminsTable admins={admins} />
        </div>
        <ProjectsTable
          listImage={org && org.icon_image_filename}
          projectsData={projects}
          itemsData={itemsData}
        />
      </ListItemsWrapper>
      <Callout type="grey">
        <StyledTitle altFont={true}>
          {itemsData && itemsData.length > 0
            ? `${
                countItems(itemsData, "location").length
              } locations cleaned up.`
            : "Loading location data..."}
        </StyledTitle>
      </Callout>
      <LocationsMapContainer>
        <iframe
          src={`https://map.debristracker.org/?dateType=all&listId=${listId}`}
          name="list_map"
          scrolling="no"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          height="100%"
          width="100%"
          allowFullScreen={true}
        ></iframe>
      </LocationsMapContainer>
    </DashboardContainer>
  );
};

export default ListDashboard;
