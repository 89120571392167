import React, { useRef, useState, useEffect } from "react";

const PathMap = ({
  path,
  items,
  id,
}: {
  path: any[];
  items: any[];
  id: number;
}) => {
  const BASEMAP = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  const ATTRIBUTION =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
  const map = useRef<L.Map>(undefined);
  const mapDivRef = useRef<any>(undefined);
  const [isBrowser, setIsBrowser] = useState<boolean>(false);

  const elementId = `${id}_map_${Math.random()}`;
  const L = require("leaflet");

  const createCustomIcon = (feature, latlng) => {
    let iconSettings = {
      mapIconUrl:
        '<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 178"><path fill="{mapIconColor}" stroke="{mapIconStroke}" stroke-width="6" stroke-miterlimit="10" d="M126 23l-6-6A69 69 0 0 0 74 1a69 69 0 0 0-51 22A70 70 0 0 0 1 74c0 21 7 38 22 52l43 47c6 6 11 6 16 0l48-51c12-13 18-29 18-48 0-20-8-37-22-51z"/><circle fill="{mapIconColorInnerCircle}" cx="74" cy="75" r="61"/><circle fill="{mapIconColorInnerCircle}" cx="74" cy="75" r="{pinInnerCircleRadius}"/>{mapText}</svg>',
      mapIconColor: "#64748a",
      mapIconStroke: "#64748aB3",
      mapIconColorInnerCircle: "#edbd7f",
      mapText: `<text style="font-size:40pt" text-anchor="middle" x="50%" y="50%">${feature.properties.quantity}</text>`,
      pinInnerCircleRadius: 48,
    };
    let icon = L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(iconSettings.mapIconUrl, iconSettings),
      iconAnchor: [18, 42],
      iconSize: [36, 42],
      popupAnchor: [0, -30],
    });
    let marker = L.marker(latlng, { icon: icon });
    return marker;
  };

  const createMap = () => {
    if (!map.current) {
      if (!mapDivRef.current) {
        return;
      }

      const center = path ? [path[0].latitude, path[0].longitude] : [37, -95];

      let l_map = L.map(elementId, {
        maxZoom: 20,
        dragging: false,
        zoomControl: false,
        scrollWheelZoom: false,
      }).setView(center, path ? 15 : 3);
      L.tileLayer(BASEMAP, {
        maxNativeZoom: 19,
        maxZoom: 20,
        attribution: ATTRIBUTION,
        noWrap: true,
      }).addTo(l_map);

      map.current = l_map;

      // add items as markers
      let geojson = [];
      for (let item of items) {
        // convert item to GeoJSON
        const geojsonPoint = {
          type: "Feature",
          properties: item,
          geometry: {
            type: "Point",
            coordinates: [item.longitude, item.latitude],
          },
        };
        // add to markers
        geojson.push(geojsonPoint);
      }

      const markers = L.geoJSON(geojson, { pointToLayer: createCustomIcon });
      markers.addTo(map.current);

      if (geojson.length > 0) {
        map.current.fitBounds(markers.getBounds(), { padding: [50, 50] });
      }

      // add path as line
      if (path) {
        const lineGeojson = L.geoJSON(
          {
            type: "LineString",
            coordinates: path.map((coords) => [
              coords.longitude,
              coords.latitude,
            ]),
          },
          { style: { color: "orange", weight: 5 } }
        );
        lineGeojson.addTo(map.current);
        map.current.fitBounds(lineGeojson.getBounds(), { padding: [50, 50] });
      }
    }
  };

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    try {
      createMap();
    } catch (e) {}
  }, [isBrowser, path]);

  if (!isBrowser) {
    return null;
  }

  return (
    <div
      style={{ width: "100%", minHeight: "300px", backgroundColor: "black" }}
      ref={mapDivRef}
      id={elementId}
    />
  );
};

export default PathMap;
