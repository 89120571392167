import React, { useEffect, useState, useRef, useCallback } from "react";
import SessionItem from "../SessionItem";

const SessionFeedList = ({ logs }: { logs: any[] }) => {
  const lastY = useRef<number>(undefined);
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const y = entries[0].boundingClientRect.y;

        // load more items if scrolled further down the page
        if (lastY.current > y) {
          loadMore();
        }
        lastY.current = y;
      },
      { threshold: 1 }
    )
  );
  const page = useRef<number>(0);

  const [loadedLogs, setLoadedLogs] = useState<any[]>([]);
  const [loadingRef, setLoadingRef] = useState(undefined);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);

  const pageSize = 3;

  const initializeList = useCallback(
    (page) => {
      if (logs) {
        const newItems = logs.slice(0, page * pageSize + pageSize);
        const newLoadedLogs = [...newItems];
        if (newItems.length === logs.length) {
          setAllLoaded(true);
        }
        setLoadedLogs(newLoadedLogs);
      }
    },
    [logs]
  );

  const loadMore = () => {
    page.current++;
    setTimeout(() => {
      initializeList(page.current);
    }, 100);
  };

  useEffect(() => {
    const currentElement = loadingRef;
    const currentObserver = observer.current;
    // initialize intersection observer
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [loadingRef]);

  useEffect(() => {
    initializeList(page.current);
  }, [initializeList, logs]);

  return (
    <>
      {loadedLogs.length > 0 &&
        loadedLogs.map((log, i) => {
          return <SessionItem key={`session_${i}`} log={log} />;
        })}
      {!allLoaded && <p ref={setLoadingRef}>Loading more sessions...</p>}
    </>
  );
};

export default SessionFeedList;
