import React from "react";
import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
} from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";

import styled from "styled-components";

const StyledCheckbox = styled(MaterialCheckbox)`
  color: "rgba(0,0,0,0.87";
`;

const Checkbox = (props) => {
  const { name, checked, onChange, content } = props;
  return (
    <StylesProvider injectFirst={true}>
      <FormControlLabel
        control={
          <StyledCheckbox
            name={name}
            color="primary"
            checked={checked}
            onChange={onChange}
          />
        }
        label={content}
      />
    </StylesProvider>
  );
};

export default Checkbox;
