import styled from "styled-components";

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 24%;
  height: auto;
  min-height: 150px;
  /* max-height: 600px; */
  border-radius: 10px;
`;

export const SessionFeedLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Lato";
`;

export const SessionFeed = {
  Container: styled.div`
    height: auto;
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    padding: 24px 12px 12px;
    position: relative;
    object-fit: fill;
  `,
  Feed: styled(Component)`
    padding: 0px;
    margin: 0px;
    width: 66%;
    height: auto;
    max-width: 700px;
    max-height: none;
    display: block;
    position: relative;
    top: 0px;
    @media only screen and (max-width: 478px) {
      width: 95%;
    }
  `,
  Profile: {
    Container: styled(Component)`
      margin-right: 24px;
      position: sticky;
      top: 24px;
      border: 1px solid #f1f1f1;
      background-color: white;
    `,
    Wrapper: styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 8px;
    `,
    Content: styled.div``,
    Picture: styled.div`
      position: relative;
      width: 64px;
      height: 64px;
      margin-right: 9px;
      border-radius: 50%;
      box-shadow: 1px 1px 12px 0 rgb(0 0 0 / 7%);
      background-color: #3f3f3f6d;
    `,
    Title: styled.h1`
      font-size: 20px;
      margin: 0px;
      line-height: 1.2;
      font-weight: 700;
      text-align: left;
      color: #333;
    `,
    Subtitle: styled.h2`
      font-size: 12px;
      height: 1.2;
      color: #333;
      margin: 0;
      margin-top: 4px;
      text-align: left;
      font-weight: normal;
    `,
  },
  Controls: {
    Wrapper: styled.div``,
    Button: styled.div``,
  },
  Stats: {
    Wrapper: styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 8px;
    `,
    Text: styled.span``,
    Label: styled.span``,
    ActivityStat: {
      Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        height: 60px;
      `,
      Text: styled.div`
        font-size: 16px;
        line-height: 20px;
        color: #333;
        font-weight: 700;
      `,
      Label: styled.div`
        font-size: 10px;
        color: #4277b9;
        font-weight: 700;
        margin-top: 6px;
      `,
    },
  },
  VerticalDivider: styled.div`
    width: 1px;
    height: 45px;
    background-color: #e6e6e6;
  `,
  ContentSection: {
    Wrapper: styled.div`
      margin-top: 8px;
      width: 100%;
      min-height: 16px;
      text-align: left;
    `,
    SmallText: styled.span`
      font-size: 11px;
      line-height: 20px;
      color: #333;
    `,
    SmallHeading: styled.h3`
      margin-top: 12px;
      margin-bottom: 4px;
      font-size: 14px;
      color: #4277b9;
      font-weight: 900;
    `,
    OrgLink: styled.div`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 8px;
      cursor: pointer;
    `,
  },
  OrgImage: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 6px;
  `,
  DoButton: styled.div`
    width: 40px;
    height: 40px;
    margin-left: 9px;
    border-radius: 50%;
    cursor: pointer;
    @media only screen and (max-width: 478px) {
      width: 30px;
      height: 30px;
    }
  `,
};

export const Log = {
  Wrapper: styled.div`
    background-color: white;
    min-height: 150px;
    padding: 24px;
    width: auto;
    border-radius: 10px;
    box-shadow: 1px 1px 12px 0 rgb(0 0 0 / 7%);
    margin-bottom: 24px;

    @media only screen and (max-width: 478px) {
      padding: 12px;
    }
  `,
  Loading: styled.div`
    background-color: white;
    min-height: 150px;
    padding: 24px;
    width: auto;
    border-radius: 10px;
    box-shadow: 1px 1px 12px 0 rgb(0 0 0 / 7%);
    margin-bottom: 24px;

    @media only screen and (max-width: 478px) {
      padding: 12px;
    }
  `,
  TextBlock: styled.div`
    display: block;
  `,
  Header: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
  `,
  Title: styled.h1`
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;
    color: #333;
    text-align: left;
    @media only screen and (max-width: 478px) {
      font-size: 18px;
    }
  `,
  OrgImage: styled.img`
    height: 66px;
    width: 66px;
    margin-right: 9px;
    border-radius: 50%;
  `,
  Subtitle: styled.h2`
    margin-top: 2px;
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    color: #4277b9;
  `,
  LocationBlock: styled.div`
    display: flex;
    align-items: stretch;
    margin-top: 4px;
  `,
  LocationText: styled.span`
    font-size: 12px;
    font-weight: normal;
    color: #333;
  `,
  OrangeBar: styled.div`
    width: 2px;
    height: 14px;
    margin: 0px 9px;
    background-color: #fabb5c;
  `,
  Map: styled.div`
    min-height: 200px;
    /* background-color: #4277b9; */
  `,
  DoButtonWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 60px;
  `,
  Stats: {
    Wrapper: styled.div`
      margin-top: 12px;
      display: flex;
      flex-direction: row;
    `,
    Item: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 16px;
      @media only screen and (max-width: 478px) {
        margin: 0px 6px;
      }
    `,
    Title: styled.span`
      font-size: 20px;
      line-height: 20px;
      color: #333;
      text-align: center;
      font-weight: bold;
      @media only screen and (max-width: 478px) {
        font-size: 12px;
      }
    `,
    Subtitle: styled.span`
      margin-top: 6px;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      color: #4277b9;
      @media only screen and (max-width: 478px) {
        font-size: 10px;
      }
    `,
  },
};
