import dayjs from "dayjs";
import React from "react";
// import Share from "./DoButtons/Share";
import Download from "./DoButtons/Download";
// import Settings from "./DoButtons/Settings";
import PathMap from "./PathMap";
import { Log } from "./SessionFeed";

const SessionItem = ({ log }: { log }) => {
  const imgSrc =
    log.project && log.project.icon_uri
      ? log.project.icon_uri
      : `https://marinedebris.engr.uga.edu/mdtapp/images/${log.organization.icon_image_filename}`;

  const items = log.items;

  const distance = log.distance ? log.distance : 0;

  return (
    <Log.Wrapper>
      <Log.Header>
        <Log.OrgImage src={imgSrc} />
        <Log.TextBlock>
          <Log.Title>{log.organization.name}</Log.Title>
          <Log.Subtitle>
            {log.project && log.project.name !== "Default" && log.project.name}
          </Log.Subtitle>
          <Log.LocationBlock>
            <Log.LocationText>{log.items[0].location}</Log.LocationText>
            {log.items[0].location && <Log.OrangeBar />}
            <Log.LocationText>
              {dayjs(log.timestamp).format("MMMM DD, YYYY")}
            </Log.LocationText>
          </Log.LocationBlock>
        </Log.TextBlock>
      </Log.Header>
      <Log.Map>
        {typeof window !== "undefined" && (
          <PathMap id={log.id} path={log.path} items={items} />
        )}
      </Log.Map>
      <Log.Stats.Wrapper>
        <Log.Stats.Item>
          <Log.Stats.Title>
            {items.reduce((prev, curr) => (prev += curr.quantity), 0)}
          </Log.Stats.Title>
          <Log.Stats.Subtitle>ITEMS</Log.Stats.Subtitle>
        </Log.Stats.Item>
        <Log.Stats.Item>
          <Log.Stats.Title>{Math.ceil(log.duration / 60)}</Log.Stats.Title>
          <Log.Stats.Subtitle>MINUTES</Log.Stats.Subtitle>
        </Log.Stats.Item>
        <Log.Stats.Item>
          <Log.Stats.Title>{distance}</Log.Stats.Title>
          <Log.Stats.Subtitle>KM</Log.Stats.Subtitle>
        </Log.Stats.Item>
        <Log.DoButtonWrapper>
          {/* TODO: <Share />  */}
          <Download
            filename={`session-${log.timestamp}.csv`}
            data={log.items}
            title={"Download this session"}
          />
          {/* <Settings /> */}
        </Log.DoButtonWrapper>
      </Log.Stats.Wrapper>
    </Log.Wrapper>
  );
};

export default SessionItem;
