import { navigate } from "gatsby-link";
import { devAPI } from "../util/urls";

export const getUserLogs = async (userId: number, accessToken: string) => {
  try {
    const url = `${devAPI}/users/${userId}/logs?detail=3`;
    let res = await fetch(url, {headers: { Authorization: `Bearer ${accessToken}`, 'Content-Length': `[500000]`} 
    });
    const parsedRes = await res.json();
    if (!parsedRes) throw "failed to load user logs";
    return parsedRes.logs;
  } catch (e) {
    // refresh token and try again
    navigate("/login?logout=true");
    return [];
  }
};
