import React, { useContext } from "react";
import { DebrisTrackerContext } from "../../context/DebrisTrackerContext";
import { navigate } from "gatsby";

export const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { user, loading } = useContext(DebrisTrackerContext);

  if (!loading && (!user || !user.id)) {
    navigate("/login");
    return null;
  }

  return <Component path={path} {...rest} />;
};
