import { useEffect, useState } from "react";
import { getUserImages } from "../../dataApi/getUserImages";

export const useUserImages = (userId: number, accessToken: string) => {
  const [images, setImages] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        let data;
        if (userId) data = await getUserImages(userId, accessToken);

        setImages(data);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [userId]);

  return { images, loading };
};
