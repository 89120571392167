import React from "react";
import BarChart from "../../DataPlatform/Charts/BarChart";
import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import { ItemInstance } from "../../../types/list";
import { colors } from "../../../styles/colors";
import styled from "styled-components";
import { MenuItem, Select } from "@material-ui/core";

const CollectionEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.black}20;
  margin: 15px;
`;

const SelectContainer = styled.div`
  max-width: 100%;
  padding: 15px;
  background-color: white;
  display: flex;
`;

const CollectionEventBarChartContainer = ({
  data,
}: {
  data: ItemInstance[];
}) => {
  const [dateRange, setDateRange] = useState<number>(30);

  if (!data || data.length <= 0) {
    return null;
  }

  // convert timestamp to drop hours/minutes/seconds
  const adjustedData = data.map((datum) => {
    datum.timestamp = dayjs(datum.timestamp).format("MM-DD-YYYY");
    return datum;
  });
  // group collection events, create object containing # events per day
  const collectionEventsPerDay = Object.entries(
    _.groupBy(adjustedData, "timestamp")
  )
    .map((entry) => {
      return {
        name: entry[0],
        data: entry[1].length,
        color: colors.orange,
      };
    })
    .filter((day) =>
      dayjs(day.name, "MM-DD-YYYY").isAfter(dayjs().subtract(1, "year"))
    )
    .sort((a, b) => {
      return dayjs(a.name).isBefore(b.name) ? -1 : 1;
    });

  // fill empty data up to today.
  const firstDate = dayjs(collectionEventsPerDay[0].name, "MM-DD-YYYY");
  const today = dayjs();

  let day = firstDate;
  let i = 0;
  while (day.diff(today) < 0) {
    let exists = false;
    collectionEventsPerDay.forEach((item) => {
      if (dayjs(item.name, "MM-DD-YYYY").isSame(day)) {
        exists = true;
      }
    });
    if (!exists) {
      collectionEventsPerDay.push({
        name: day.format("MM-DD-YYYY"),
        data: 0,
        color: colors.orange,
      });
    }
    day = day.add(1, "d");
    i++;
  }

  collectionEventsPerDay.sort((a, b) => {
    return dayjs(a.name).isBefore(b.name) ? -1 : 1;
  });

  // take last `dateRange` events
  const barChartData = collectionEventsPerDay.slice(-dateRange);

  // setup chart options
  const chartConfig: Highcharts.Options = {
    chart: {
      type: "column",
      animation: false,
    },
    title: {
      text: `Collection Events in the last ${dateRange} days`,
    },
    xAxis: {
      categories: barChartData.map((d) => d.name),
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Items (count)",
        align: "low",
      },
      labels: {
        overflow: "allow",
      },
    },
    tooltip: {
      useHTML: true,
      valueSuffix: " events",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Count",
        type: "column",
        colorByPoint: true,
        colors: [colors.orange],
        data: barChartData.map((d) => {
          return { name: d.name, y: d.data };
        }),
        animation: false,
      },
    ],
  };

  return (
    <CollectionEventContainer>
      <BarChart data={barChartData} customOptions={chartConfig} />
      <SelectContainer>
        <Select
          value={dateRange}
          onChange={(e) => {
            setDateRange(e.target.value as number);
          }}
        >
          <MenuItem value={30}>30 days</MenuItem>
          <MenuItem value={60}>60 days</MenuItem>
          <MenuItem value={90}>90 days</MenuItem>
          <MenuItem value={360}>1 year</MenuItem>
        </Select>
      </SelectContainer>
    </CollectionEventContainer>
  );
};

export default CollectionEventBarChartContainer;
