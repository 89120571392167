import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import _ from "lodash";
import { colors } from "../../../styles/colors";
import { Box, Collapse, IconButton, Typography } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "../../Forms/Checkbox";
import {
  TableTitle,
  TableContainer,
  TableTitleContainer,
  useRowStyles,
  StyledTableSortLabel,
  WhiteCheckbox,
} from "../../../styles/TableComponents";
import { useRef } from "react";
import { ItemInstance } from "../../../types/list";
import { JSONtoCSV } from "../../../util/data/JSONtoCSV";

interface ListUsersTableRow {
  username: string;
  userId: number;
  count: number;
  items: ItemInstance[];
}

const ListUsersContainer = ({
  userData,
}: {
  userData: ListUsersTableRow[];
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<keyof ListUsersTableRow>("count");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [selectedUsersData, setSelectedUsersData] = useState<string>("");

  useEffect(() => {
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    const newData = createSelectedUsersData();
    setSelectedUsersData(newData);
  }, [selectedUsers]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = userData.map((user) => `${user.userId}`);
      setSelectedUsers(newSelected);
      return;
    }
    setSelectedUsers([]);
  };

  const handleClick = (userId: string) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelected);
  };

  const isSelected = (userId: string) => selectedUsers.indexOf(userId) !== -1;

  const createSelectedUsersData = () => {
    if (selectedUsers.length === 0) {
      return "";
    }
    const selectedUsersGrouped = Object.entries(_.groupBy(userData, "userId"))
      .filter((entry) => selectedUsers.includes(entry[0]))
      .map((userEntry) => userEntry[1].map((userData) => userData.items))
      .reduce((arr, userData) => arr.concat(userData))
      .reduce((selectedUsersData, innerUserData) =>
        selectedUsersData.concat(innerUserData)
      );
    return JSONtoCSV(selectedUsersGrouped);
  };

  const downloadSelectedUserData = (linkRef) => {
    linkRef.current && linkRef.current.link.click();
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof ListUsersTableRow
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const descendingComparator = (a: any, b: any, orderBy: keyof any) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: "asc" | "desc", orderBy: any) => {
    return order === "desc"
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
    const stabilizedThis = array.map(
      (el, index) => [el, index] as [any, number]
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const headers: { label: keyof ListUsersTableRow; name: string }[] = [
    { label: "userId", name: "Id" },
    { label: "username", name: "Username" },
    { label: "count", name: "Total Items" },
  ];

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, userData.length - page * rowsPerPage);

  const ListUserItemsTable = ({
    row,
    open,
  }: {
    row: ListUsersTableRow;
    open: boolean;
  }) => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const groupedItems = Object.entries(_.groupBy(row.items, "itemname")).map(
      (itemData) => {
        return {
          name: itemData[0],
          items: itemData[1],
          count: itemData[1].reduce((prev, curr) => {
            return (prev += curr.quantity);
          }, 0),
        };
      }
    );

    const emptyItemsRows =
      rowsPerPage -
      Math.min(rowsPerPage, groupedItems.length - page * rowsPerPage);

    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box margin={1}>
          <Typography variant="h6" gutterBottom component="div">
            Items
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedItems
                .sort((a, b) => b.count - a.count)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((itemsRow, i) => (
                  <TableRow key={i}>
                    <TableCell align="right">{itemsRow.name}</TableCell>
                    <TableCell align="right">{itemsRow.count}</TableCell>
                  </TableRow>
                ))}
              {emptyItemsRows > 0 && (
                <TableRow style={{ height: 33 * emptyItemsRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  page={page}
                  colSpan={4}
                  count={groupedItems.length}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(_e, newPage) => {
                    setPage(newPage);
                  }}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </Collapse>
    );
  };

  const ListUsersTableRow = ({ userData }: { userData: ListUsersTableRow }) => {
    const [open, setOpen] = useState<boolean>(false);
    const classes = useRowStyles();

    const isItemSelected = isSelected(`${userData.userId}`);

    return (
      <>
        <TableRow hover={true} className={classes.root}>
          <TableCell
            style={{ paddingLeft: "10px" }}
            padding="none"
            align="left"
          >
            <Checkbox
              checked={isItemSelected}
              onChange={() => {
                handleClick(`${userData.userId}`);
              }}
            />
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen((prev) => !prev);
              }}
            >
              {open ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </IconButton>
          </TableCell>
          {headers.map((header, i) => (
            <TableCell
              key={`table_cell_${i}`}
              style={{ fontFamily: "Lato" }}
              align={header.label === "count" ? "right" : "center"}
            >
              {userData[header.label]}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, fontFamily: "Lato" }}
            colSpan={4}
          >
            <ListUserItemsTable open={open} row={userData} />
          </TableCell>
        </TableRow>
      </>
    );
  };

  const ListUsersTableHead = ({ onRequestSort }) => {
    const createSortHandler = (property: keyof ListUsersTableRow) => (
      event: React.MouseEvent<unknown>
    ) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead style={{ backgroundColor: colors.blue }}>
        <TableRow>
          <TableCell padding="none" align="left" style={{ paddingLeft: "0px" }}>
            <WhiteCheckbox
              onChange={handleSelectAllClick}
              checked={
                selectedUsers.length === userData.length && userData.length > 0
              }
            />
          </TableCell>
          {headers.map((headCell, i) => {
            return (
              <TableCell key={i} align="center">
                <StyledTableSortLabel
                  active={orderBy === headCell.label}
                  direction={orderBy === headCell.label ? order : "asc"}
                  onClick={createSortHandler(headCell.label)}
                >
                  {headCell.name}
                  {orderBy === headCell.name ? (
                    <span>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </StyledTableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const ListUsersTableTitle = () => {
    const linkRef = useRef<any>(undefined);
    if (selectedUsers.length > 0) {
      return (
        <TableTitleContainer>
          <TableTitle altFont={true}>
            {`${selectedUsers.length} user${
              selectedUsers.length > 1 ? "s" : ""
            } selected`}
          </TableTitle>
          <IconButton
            onClick={() => {
              downloadSelectedUserData(linkRef);
            }}
          >
            <FontAwesomeIcon color={colors.white} icon={faDownload} />
          </IconButton>
          <CSVLink
            target="_blank"
            ref={linkRef}
            filename={`users-mdt-data.csv`}
            data={selectedUsersData}
          />
        </TableTitleContainer>
      );
    }
    return <TableTitle altFont={true}>Users</TableTitle>;
  };

  return (
    <TableContainer>
      <ListUsersTableTitle />
      {!loading && userData && (
        <Table>
          <ListUsersTableHead onRequestSort={handleRequestSort} />
          <TableBody>
            {stableSort(userData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => (
                <ListUsersTableRow userData={row} key={i} />
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                page={page}
                colSpan={4}
                count={userData.length}
                rowsPerPage={rowsPerPage}
                onPageChange={(e, newPage) => {
                  e.preventDefault();
                  setPage(newPage);
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(parseInt(e.target.value, 10));
                  setPage(0);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </TableContainer>
  );
};

export default ListUsersContainer;
