import { navigate } from "gatsby-link";
import { devAPI } from "../util/urls";

export const getUserImages = async (userId: number, accessToken: string) => {
  try {
    if (!userId) return [];
    const url = `${devAPI}/users/${userId}/images`;
    let res = await fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const parsedRes = await res.json();

    if (!parsedRes) throw "failed to load images";
    return parsedRes.images;
  } catch (e) {
    //navigate to login
    navigate("/login?logout=true");
    return null;
  }
};
