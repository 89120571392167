import React from "react";
import { ProjectsEntity } from "../../../types/list";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import { TableContainer, TableTitle } from "../../../styles/TableComponents";
import { colors } from "../../../styles/colors";
import numberWithCommas from "../../../util/numberWithCommas";
import { IconButton, TableFooter, TablePagination } from "@material-ui/core";
import { faDownload, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JSONtoCSV } from "../../../util/data/JSONtoCSV";
import { CSVLink } from "react-csv";
import { useRef } from "react";
import { useState } from "react";
import { verifyProjectById } from "../../../dataApi/verifyProject";

interface ProjectTableEntity extends ProjectsEntity {
  itemCount: number;
  logCount: number;
}

const ProjectsTable = ({
  projectsData,
  itemsData,
  listImage,
}: {
  listImage: string;
  projectsData: ProjectsEntity[];
  itemsData: any[];
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const groupedProjects = _.groupBy(itemsData, "project_id");
  const itemsByProjectId = Object.entries(groupedProjects);

  const logsByProject = itemsByProjectId.map((value) => {
    const groupedByLog = _.groupBy(value[1], "log_index");
    return [value[0], Object.keys(groupedByLog).length];
  });

  const headers = [
    { name: "Id", label: "id" },
    { name: "Project Name", label: "name" },
    { name: "Verified", label: "verified" },
  ];

  const ProjectsTableRow = ({ project }: { project: ProjectTableEntity }) => {
    const linkRef = useRef<any>(undefined);

    const [projectData, setProjectData] = useState<ProjectTableEntity>(project);

    return (
      <TableRow>
        <TableCell>
          {projectData.icon_uri ? (
            <img width={50} height={50} src={projectData.icon_uri} />
          ) : (
            <img
              width={50}
              height={50}
              src={
                listImage &&
                `https://marinedebris.engr.uga.edu/mdtapp/images/${listImage}`
              }
            />
          )}
        </TableCell>
        {headers.map((header, i) => (
          <TableCell key={`header_${i}`} align="center">
            {projectData[header.label]}
          </TableCell>
        ))}

        <TableCell align="right">
          {numberWithCommas(projectData.itemCount)}
        </TableCell>
        <TableCell>{numberWithCommas(projectData.logCount)}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              linkRef.current && linkRef.current.link.click();
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </IconButton>
          <CSVLink
            target="_blank"
            ref={linkRef}
            filename={`project-${projectData.name
              .trim()
              .toLocaleLowerCase()
              .split(" ")
              .join("-")}-mdt-data.csv`}
            data={JSONtoCSV(groupedProjects[projectData.id])}
          ></CSVLink>
          {projectData.verified === 0 && (
            <IconButton
              onClick={async () => {
                try {
                  const verifiedProject = await verifyProjectById(
                    projectData.id
                  );
                  if (verifiedProject) {
                    alert(verifiedProject);
                    setProjectData({ ...projectData, verified: "1" });
                  }
                } catch (e) {
                  alert(e);
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const ProjectsTableTitle = () => {
    return <TableTitle altFont={true}>Projects</TableTitle>;
  };

  const cellStyle = { color: colors.white };

  //add computed aggregates to project object
  projectsData.forEach((project) => {
    let projectItemCount: number;
    let projectLogCount: number | string;

    if (itemsByProjectId.length > 0) {
      const filtered = itemsByProjectId.filter((item) => {
        if (item[0] === "null" && project.name === "Default") {
          return true;
        }
        return item[0] === project.id;
      });
      if (filtered.length > 0) {
        projectItemCount = filtered.reduce(
          (prev, curr) => (curr[1].length += prev),
          0
        );
      }
    }

    if (logsByProject.length > 0) {
      const filtered = logsByProject.filter((item) => {
        if (item[0] === "null" && project.name === "Default") {
          return true;
        }
        return item[0] === project.id;
      });
      if (filtered.length > 0) {
        projectLogCount = filtered.reduce((prev, curr) => {
          if (typeof curr[1] === "number") {
            return (prev += curr[1]);
          } else if (typeof curr[1] === "string") {
            return (prev += parseInt(curr[1], 10));
          }
        }, 0);
      }
    }

    project["itemCount"] = projectItemCount || 0;
    project["logCount"] = projectLogCount || 0;
  });

  const emptyItemsRows =
    rowsPerPage -
    Math.min(rowsPerPage, projectsData.length - page * rowsPerPage);

  return (
    <TableContainer>
      <ProjectsTableTitle />
      <Table>
        <TableHead style={{ backgroundColor: colors.blue }}>
          <TableRow>
            <TableCell />
            {headers.map((header, i) => (
              <TableCell key={`${i}`} align="center" style={cellStyle}>
                {header.name}
              </TableCell>
            ))}
            <TableCell style={cellStyle}>Items</TableCell>
            <TableCell style={cellStyle}>Logs</TableCell>
            <TableCell style={cellStyle}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsByProjectId &&
            logsByProject &&
            projectsData
              .sort(
                (a: ProjectTableEntity, b: ProjectTableEntity) =>
                  b.itemCount - a.itemCount
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((project: ProjectTableEntity, i) => (
                <ProjectsTableRow key={`project_row_${i}`} project={project} />
              ))}
          {emptyItemsRows > 0 && (
            <TableRow style={{ height: 33 * emptyItemsRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              page={page}
              colSpan={4}
              count={projectsData.length}
              rowsPerPage={rowsPerPage}
              onPageChange={(_e, newPage) => {
                setPage(newPage);
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ProjectsTable;
