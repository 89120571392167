import React, { useRef } from "react";
import { SessionFeed } from "../SessionFeed";
import styled from "styled-components";
import download from "../../../../images/mdt_download_icon.png";
import { CSVLink } from "react-csv";

const DownloadButton = styled(SessionFeed.DoButton)`
  background-color: #414d5d;
  background-image: url(${download});
  background-position: 55% 50%;
  background-size: 40%;
  background-repeat: no-repeat;
`;

const Download = ({ title, data, filename }: { title; data; filename }) => {
  const csvRef = useRef<any>(undefined);
  return (
    <>
      <DownloadButton
        title={title}
        onClick={() => {
          csvRef && csvRef.current && csvRef.current.link.click();
        }}
      />
      {data && data.length > 0 && (
        <CSVLink filename={filename} data={data} ref={csvRef} />
      )}
    </>
  );
};

export default Download;
