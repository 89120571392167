import { useEffect, useState } from "react";
import { getUserLogs } from "../../dataApi/getUserLogs";

export const useUserSessions = (userId: number, accessToken: string) => {
  const [logs, setLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let data: any[];
        if (userId) data = await getUserLogs(userId, accessToken);
        setLogs(data);
      } catch (e) {
        setLogs([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [userId]);

  return { logs, loading };
};
