import * as React from "react";
import DashboardRoot from "../components/Dashboards/DashboardRoot";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";

const UserPage = () => {
  return (
    <Layout vertical={true} color={"#f5f9ff"}>
      <Helmet>
        <script src="https://kit.fontawesome.com/4673245c66.js"></script>
      </Helmet>
      <Seo title="Dashboard" />
      <DashboardRoot />
    </Layout>
  );
};

export default UserPage;
