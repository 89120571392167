import { SurveyResult } from "../../types/survey";

export const surveyJSONtoCSV = (data: SurveyResult[]) => {
  try {
    let csv_data = [];
    //get headers

    const firstRowSurvey = data[0].survey_json;
    const title = firstRowSurvey.title;
    const first_row = ["log_id", "title", "user_id"];
    for (let question of firstRowSurvey.questions) {
      first_row.push(question.text);
    }
    csv_data.push(first_row);
    for (var datum of Object.values(data)) {
      const log_id = datum.log_id;
      const user_id = datum.user_id;
      const row = [log_id, title, user_id];
      const answers = datum.results_json;
      if (answers) {
        for (let answer of answers) {
          row.push(answer[0]);
        }
      } else {
        for (
          let i = 0;
          i < Object.values(firstRowSurvey.questions).length;
          i++
        ) {
          row.push("null");
        }
      }
      csv_data.push(row);
    }
    return csv_data.map((row) => row.join(",")).join("\n");
  } catch (err) {
    return undefined;
  }
};
