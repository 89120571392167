import React from "react";
import { Log } from "./SessionFeed";

const SessionItemLoading = () => {
  return (
    <Log.Loading>
      <Log.Header>
        <Log.TextBlock>
          <Log.Title>Loading data...</Log.Title>
        </Log.TextBlock>
      </Log.Header>
      <Log.Map />
    </Log.Loading>
  );
};

export default SessionItemLoading;
