import React from "react";
import { Router } from "@reach/router";
import ListDashboard from "./ListDashboard";
import UserDashboard from "./UserSessionFeed/UserSessionFeed";
import { PrivateRoute } from "../Routing/PrivateRoute";

const DashboardRoot = () => {
  return (
    <Router basepath="/dashboard">
      <PrivateRoute path="/user" component={UserDashboard} />
      <PrivateRoute path="/list/:listId" component={ListDashboard} />
    </Router>
  );
};

export default DashboardRoot;
