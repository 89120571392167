import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  TableContainer,
  TableTitle,
  TableTitleContainer,
} from "../../../styles/TableComponents";
import { colors } from "../../../styles/colors";
// import { IconButton } from "@material-ui/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
// import AddAdminModal from "./Modals/AddAdminModal";

const ListAdminsTable = ({ admins }: { admins: any[] }) => {
  // const [showAdminModal, setShowAdminModal] = useState(false);

  const headers = [
    { label: "userId", name: "Id" },
    { label: "username", name: "Username" },
    { label: "email", name: "Email" },
  ];

  return (
    <TableContainer>
      {/* <AddAdminModal visible={showAdminModal} setVisible={setShowAdminModal} /> */}
      <TableTitleContainer style={{ opacity: 1 }}>
        <TableTitle altFont={true}>Admins</TableTitle>
        {/* <IconButton
          onClick={() => {
            setShowAdminModal(true);
          }}
        >
          <FontAwesomeIcon color={colors.white} icon={faPlusSquare} />
        </IconButton> */}
      </TableTitleContainer>
      {/* TODO: add admin by email */}
      <Table>
        <TableHead style={{ backgroundColor: colors.blue }}>
          {headers.map((headCell, i) => {
            return (
              <TableCell key={i} style={{ color: "white" }} align="center">
                {headCell.name}
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {admins &&
            admins.map((admin, i) => (
              <TableRow key={i}>
                <TableCell align="center">{admin.id}</TableCell>
                <TableCell align="center">{admin.username}</TableCell>
                <TableCell align="center">{admin.email}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListAdminsTable;
