import React, { useContext, useEffect } from "react";
import { DebrisTrackerContext } from "../../../context/DebrisTrackerContext";
import { useUserSessions } from "../../../util/hooks/useUserSessions";
import { SessionFeed, SessionFeedLayout } from "./SessionFeed";
import Profile from "./Profile/Profile";
import SessionFeedList from "./Feed/SessionFeedList";
import { useDetectDevice } from "../../../util/hooks";
import SessionItemLoading from "./SessionItemLoading";

const UserSessionFeed = () => {
  const { user } = useContext(DebrisTrackerContext);

  const { logs, loading } = useUserSessions(user.id, user.aws_access_token);

  const { isMobile } = useDetectDevice();

  return (
    <SessionFeedLayout>
      <SessionFeed.Container>
        {!isMobile && <Profile user={user} logs={logs} loading={loading} />}
        <SessionFeed.Feed>
          {!loading && logs && logs.length > 0 ? (
            <SessionFeedList logs={logs} />
          ) : (
            <SessionItemLoading />
          )}
        </SessionFeed.Feed>
      </SessionFeed.Container>
    </SessionFeedLayout>
  );
};

export default UserSessionFeed;
